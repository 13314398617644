
export default {
  SET_NULL_TASKS (state) {
    state.jadwals = []
  },

  // TASK
    SET_JADWALS (state, jadwals) {
      state.jadwals = jadwals
    },
    
    ADD_UPDATE (state, item) {
      state.jadwals.unshift(item)
    },
    UPDATE_DATA (state, item) {
      const taskIndex = state.jadwals.findIndex((p) => p.id === item.id)
      Object.assign(state.jadwals[taskIndex], item)
    },
    EDIT_UPDATE (state, item) {
      //alert(item.id_before)
      const jadwalIndex = state.jadwals.findIndex((p) => p.id === parseInt(item.id_before))
      Object.assign(state.jadwals[jadwalIndex], item)
    },
    REMOVE_JADWAL (state, id) {
      const ItemIndex = state.jadwals.findIndex((p) => p.id === id)
      state.jadwals.splice(ItemIndex, 1)
    },

}
