
import state from './moduleJadwalState.js'
import mutations from './moduleJadwalMutations.js'
import actions from './moduleJadwalActions.js'
import getters from './moduleJadwalGetters.js'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
