<template>
   <div id="simple-calendar-app">
      
      <div class="vx-card fix-content my-base">
         <calendar-view
         ref="calendar"
         :displayPeriodUom="calendarView"
         :show-date="showDate"
         :events="tanggalEvent"
         eventBorderHeight="0px"
         eventContentHeight="1px"
         class="theme-default"
         @click-date="openAddNewEvent"
         @click-event="openViewEvent">
            <div slot="header" class="mb-8">
               <div class="vx-row no-gutter">
                  <div class="vx-col w-full">
                     <div class="flex items-center justify-between">
                        <feather-icon
                           :icon="$vs.rtl ? 'ChevronRightIcon' : 'ChevronLeftIcon'"
                           @click="updateMonth(-1)"
                           svgClasses="w-5 h-5 m-1"
                           class="cursor-pointer bg-primary text-white rounded-full" />

                        <span class="mx-3 text-xl font-medium whitespace-no-wrap">{{ showDate | month }}</span>

                        <feather-icon
                           :icon="$vs.rtl ? 'ChevronLeftIcon' : 'ChevronRightIcon'"
                           @click="updateMonth(1)"
                           svgClasses="w-5 h-5 m-1"
                           class="cursor-pointer bg-primary text-white rounded-full" />
                     </div>
                  </div>
               </div>
            </div>
         </calendar-view>
      </div>

      <vx-card class="mb-12" title-color="primary" title="Keterangan :">
      <div class="vx-row">

         <div class="vx-col w-1/2 mb-5 flex items-center">
            <vs-button color="warning" type="filled" icon-pack="feather" icon="icon-archiv" class="mr-2"></vs-button>
            <p style="font-size: 12px;">Tanggal Terpilih</p>
         </div>

         <div class="vx-col w-1/2 mb-5 flex items-center">
            <vs-button color="danger" type="filled" icon-pack="feather" icon="icon-archiv" class="mr-2"></vs-button>
            <p style="font-size: 12px;">Kuota Penuh</p>
         </div>

         <!--
         <div class="vx-col w-1/2 mb-5 flex items-center">
            <vs-button color="primary" type="border" icon-pack="feather" icon="icon-archiv" class="mr-2"></vs-button>
            <p style="font-size: 12px;">Tanggal</p>
         </div>

         <div class="vx-col w-1/2 mb-5 flex items-center">
            <vs-button color="warning" type="filled" icon-pack="feather" icon="icon-archiv" class="mr-2"></vs-button>
            <p style="font-size: 12px;">Hampir Penuh</p>
         </div>
         -->

         <vs-divider></vs-divider>
         
            <p class="px-5 mb-2">1. Mohon anda memastikan kehadiran anda pada tanggal terpilih.</p>
            <p class="px-5">2. Pastikan kelengkapan dokumen terpenuhi.</p>

      </div>
      </vx-card>

      <!-- ADD EVENT -->
      <vs-prompt
      class="calendar-event-dialog"
      title="Tambah Kunjungan"
      accept-text= "Berkunjung"
      @accept="addEvent"
      :is-valid="validForm"
      :active.sync="activePromptAddEvent">
         <div class="mb-5">
            <small class="date-label">Tanggal Kunjungan</small>
            <datepicker :language="$vs.rtl ? langHe : langEn" name="start-date" v-model="startDate" :disabled="disabledFrom"></datepicker>
         </div>
         <!--
         <div class="my-4">
            <vs-select
            label="Pilih Waktu"
            class="w-full pb-5"
            v-model="kunjunganJam"
            >
               <vs-select-item v-for="(waktu, index) in jam" :value="waktu.value" :text="waktu.text" :key="index"/>
            </vs-select>
         </div>
         -->
         <div class="my-4">
            <vs-select
            label="Pilih Permohonan"
            class="w-full pb-5"
            v-model="dataPermohonan"
            >
               <vs-select-item value="0" text="Pilih"/>
               <vs-select-item v-for="(data, index) in listPermohonan" :value="data.id" :text="data.task_title" :key="index"/>
            </vs-select>
         </div>
      </vs-prompt>

      <!-- Nomor Urut -->
      <vs-popup
         title="Nomor Urut Panggilan"
         :active.sync="activePromptUrut">

         <div class="text-center">
            <h1 class="mb-4" style="font-size:xxx-large">{{nomorUrut}}</h1>
            <p class="text-sm">Perhatikan nomor urut anda ketika dipanggil</p>
         </div>

      </vs-popup>

      <!-- EDIT EVENT -->
      <vs-popup :active.sync="activePromptViewEvent" 
         title="Permohonan antrian">
         <vs-list>
            <vs-list-item v-for="(a, index) in listAntrianUser" :title="a.pemohon" :subtitle="a.berkas_judul" :key="index">
               <vs-button type="border" size="small" icon-pack="feather" icon="icon-chevron-right" class="mr-5" @click="showUrut(a)"></vs-button>
            </vs-list-item>
         </vs-list>
         <div class="flex w-full justify-end mt-5">
            <vs-button type="filled" size="" icon-pack="feather" icon="icon-calendar" class="mr-5" @click="openAddNewEvent(listAntrianUser[0].tanggal)">Tambah Antrian</vs-button>
         </div>
      </vs-popup>

   </div>
</template>

<script>
import { CalendarView, CalendarViewHeader } from 'vue-simple-calendar'
require('vue-simple-calendar/static/css/default.css')

import Datepicker from 'vuejs-datepicker'
import { en, he } from 'vuejs-datepicker/src/locale'

import { mapGetters } from 'vuex'

import moduleJadwal from '@/stores/jadwal/moduleJadwal.js'

export default {
   components: {
      CalendarView,
      CalendarViewHeader,
      Datepicker
   },
   data () {
      return {
         showDate: new Date(),
         disabledFrom: false,
         id: 0,
         title: '',
         startDate: '',
         endDate: '',
         labelLocal: 'none',
         dataJadwal: {},
         dataPemohon: {},

         langHe: he,
         langEn: en,

         url: '',
         calendarView: 'month',

         activePromptAddEvent: false,
         activePromptViewEvent: false,

         nomorUrut: 0,
         activePromptUrut: false,
         
         listPermohonan: [],
         dataPermohonan: 0,
         
         listAntrianUser: [],
         

         antrianMax: 0,

         calendarViewTypes: [
         {
            label: 'Month',
            val: 'month'
         },
         {
            label: 'Week',
            val: 'week'
         },
         {
            label: 'Year',
            val: 'year'
         }
         ],

         jam : [
         { value:"0", text:"Pilih" },
         { value:"08", text:"08:00" },
         { value:"09", text:"09:00" },
         { value:"10", text:"10:00" },
         { value:"11", text:"11:00" },
         { value:"13", text:"13:00" },
         { value:"14", text:"14:00" },
         { value:"15", text:"15:00" },
         { value:"16", text:"16:00" }
         ],
         kunjunganJam: '0',
         dataID: 0,
         dataTitle: 0
      }
   },
   computed: {
      ...mapGetters({
         user : 'auth/user',
      }),
      tanggalEvent () {
         const kunjungan = []
         let i = 0
         for (const element of this.$store.state.jadwalTanggal.jadwals) {
            let obj = {
               id: i,
               title: this.tanggalSaja(element.tanggal),
               startDate: new Date(element.tanggal),
               endDate: new Date(element.tanggal),
               url: '',
               classes: this.eventStatus(element),
               label: '',
               pengunjung: element.pengunjung
            }
            i++
            kunjungan.push(obj)
         }
         return kunjungan
      },
      validForm () {
         // && this.kunjunganJam !== '0'
         return this.startDate !== '' && this.dataPermohonan !== 0
      },
      disabledDatesTo () {
         return { to: new Date(this.startDate) }
      },
      disabledDatesFrom () {
         return { from: new Date(this.endDate) }
      },
      windowWidth () {
         return this.$store.state.windowWidth
      }
   },
   mounted () {
      
      this.axios.get('/options-select-name/antrian_max')
      .then((response) => {
         this.antrianMax = parseInt(response.data.data.option_value)
      })

      this.listLoadPermohonan()

   },
   methods: {
      addEvent () {
         /*
         let stringJam = String(this.dataJadwal.kunjungan_jam)
         +stringJam
         */
         //const ItemIndex = this.tanggalEvent.find((ev) => ev.startDate === this.startDate)
         let tgls = []
         tgls = this.tanggalEvent.filter(val => val.title === this.startDate);
         if (tgls.length != this.antrianMax) {
        
            this.$vs.loading()
            let formData = new FormData()
            
            const ItemIndex = this.listPermohonan.find((berkas) => berkas.id === this.dataPermohonan)

            formData.set('title', 'Antrian - '+ ItemIndex.task_title)
            formData.set('status', 'publish')
            formData.set('comment_status', 'open')
            formData.set('password', null)
            formData.set('parent', this.dataPermohonan)
            formData.set('type', 'antrian_app')
            formData.set('comment_count', 0)
            
            //formData.set('get_parent', 'true')
            
            let stringDate = String(this.formatDate(this.startDate))
            let gabung = stringDate
            let reg = gabung.replaceAll('-', '')
            formData.set('kedatangan_reg', 'antrian_app'+reg)

            let meta = {
               pemohon: ItemIndex.nama,
               berkas_judul: ItemIndex.berkas_name,
               kunjungan_tanggal: this.formatDateIndo(this.startDate),
               hari: this.getHari(this.startDate),
               tanggal: this.formatDate(this.startDate)
            }
            formData.set('meta', JSON.stringify(meta))
            
            this.$store.dispatch('kedatanganAdd', formData)
            .then((response) => {
               this.dataKedatangan = response.data
               let urut = parseInt(this.dataKedatangan.kedatangan_urut)
               this.nomorUrut = ('00' + urut).slice(-3)
               this.activePromptViewEvent = false
               this.activePromptUrut = true
               this.dataPermohonan = 0
               this.$vs.notify({
                  title: 'Berhasil',
                  color  : 'success',
                  text  : response.message,
               })
               this.$store.commit('jadwalTanggal/SET_NULL_TASKS')
               const obj = {
                  type: 'antrian_app',
                  urutan: 'asc'
               }
               this.$store.dispatch('jadwalTanggal/fetchJadwal', obj)
               this.listLoadPermohonan()
               this.$vs.loading.close()
               //this.listAntrianUser.push(this.dataKedatangan)
            })
            .catch(error => {
               this.$vs.loading.close()
               let {data} = error.response
               this.$vs.notify({
                  title: 'Error',
                  text: data.message,
                  iconPack: 'feather',
                  icon: 'icon-alert-circle',
                  color: 'danger'
               })
            })
         } else {
            this.$vs.notify({
               title: 'Maaf',
               text: 'Quota sudah penuh',
               iconPack: 'feather',
               icon: 'icon-alert-circle',
               color: 'danger'
            })
         }
      },
      openAddNewEvent (date) {
         this.disabledFrom = true
         if (this.listAntrianUser.length >= this.antrianMax) {
            this.$vs.notify({
               title: 'Maaf',
               text: 'Anda hanya dapat mendapat '+this.antrianMax+'  antrian per hari',
               iconPack: 'feather',
               icon: 'icon-alert-circle',
               color: 'danger'
            })
         } else {
            this.activePromptViewEvent = false
            var d = new Date()
            var n = new Date()
            const dateadd = d.setDate(d.getDate() + 10)
            if (this.dateCalc(date) < this.dateCalc(n)) {
               this.$vs.notify({
                  title: 'Maaf',
                  text: 'Tanggal sudah lewat',
                  iconPack: 'feather',
                  icon: 'icon-alert-circle',
                  color: 'danger'
               })
            }
            else if (this.dateCalc(date) > this.dateCalc(dateadd)) {
               this.$vs.notify({
                  title: 'Maaf',
                  text: 'Kami belum buka quota antrian 10 hari kedepan',
                  iconPack: 'feather',
                  icon: 'icon-alert-circle',
                  color: 'danger'
               })
            } 
            else {
               this.addNewEventDialog(date)
            } 
         }
      },
      dateCalc(date) {
         var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
         if (month.length < 2) 
            month = '0' + month;
         if (day.length < 2) 
            day = '0' + day;
         return [year, month, day].join('');
      },
      addNewEventDialog (date) {
         this.clearFields()
         this.startDate = date
         this.endDate = date
         this.activePromptAddEvent = true
      },
      formatDate(date) {
         var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

         if (month.length < 2) 
            month = '0' + month;
         if (day.length < 2) 
            day = '0' + day;

         return [year, month, day].join('-');
      },
      getHari(date) {
         var today = new Date(date).getDay();
         var days = ['Minggu','Senin','Selasa','Rabu','Kamis','Jumat','Sabtu'];
         return days[today];
      },
      formatDateIndo(date) {
         var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

         if (month.length < 2) 
            month = '0' + month;
         if (day.length < 2) 
            day = '0' + day;

         return [day, month, year].join('-');
      },
      tanggalSaja(date) {
         var d = new Date(date),
            day = '' + d.getDate();
         if (day.length < 2) 
            day = '0' + day;

         return day;
      },
      updateMonth (val) {
         this.showDate = this.$refs.calendar.getIncrementedPeriod(val)
      },
      clearFields () {
         this.title = this.endDate = this.url = ''
         this.id = 0
         this.labelLocal = 'none'
      },
      openViewEvent (event) {
         if (event.classes[0] === 'event-danger') {
            this.$vs.notify({
               title: '',
               text: 'Quota sudah memenuhi',
               iconPack: 'feather',
               icon: 'icon-alert-circle',
               color: 'danger'
            })
         } else if (event.classes[0] === 'event-warning') {
            //const ItemIndex = this.$store.state.jadwalTanggal.jadwals.find((kun) => kun.id === event.id)

            //console.log(event.originalEvent.pengunjung)
            
            let username = this.user.username
            this.listAntrianUser = event.originalEvent.pengunjung.filter(val => val.author === username);
            this.activePromptViewEvent = true
            
         } else {
            this.disabledFrom = true
            this.startDate = event.startDate
            this.activePromptAddEvent = true
         }
      },
      eventStatus(el) {
         const ItemIndex = el.pengunjung.find((kun) => kun.author === this.user.username)
         if (ItemIndex) {
            return 'event-warning'
         } else if (this.antrianMax === el.pengunjung.length) { 
            return 'event-danger'
         } else { 
            return 'event-none'
         }
      },
      showUrut (a) {
         this.nomorUrut = ('00' + a.urut).slice(-3)
         this.activePromptUrut = true
      },
      listLoadPermohonan () {
         let formData = new FormData()
         formData.set('type', 'berkas')
         formData.set('urutan', 'desc')
         let config = { headers: { 'Authorization': 'Bearer ' + this.user.api_token } }
         this.axios.post('/task-list-select-antrian', formData, config)
         .then((response) => {
            this.listPermohonan = response.data.data
         })
      }
   },
   created () {
      this.$vs.loading()
      this.$store.commit('navbarUpdateStatus', false)

      this.$store.registerModule('jadwalTanggal', moduleJadwal)
      const obj = {
      type: 'antrian_app',
      urutan: 'asc'
      }
      this.$store.dispatch('jadwalTanggal/fetchJadwal', obj)
      setTimeout(() => {
         this.$vs.loading.close()
      }, 5000)
   },
   beforeDestroy () {
   }
}
</script>

<style lang="scss">
@import "@/assets/scss/vuexy/apps/calendar-jadwal.scss";
.fix-content {
   height: 28rem;
}
.includeIconOnly {
  width: 30px!important;
  height: 30px!important;
}
</style>
