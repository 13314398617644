
import axios from '@/axios.js'

export default {
  // TASK
    fetchJadwal ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios.post('/task-list-tanggal', payload)
          .then((response) => {
            commit('SET_JADWALS', response.data.data)
            resolve(response)
          })
          .catch((error) => { reject(error) })
      })
    },

    addUpdate ({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.post('/task-add', data)
        .then((response) => {
          commit('ADD_UPDATE', response.data.data)
          resolve(response.data)
        })
        .catch((error) => { reject(error) })
      })
    },

    updateData ({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.post('/task-update', data)
        .then((response) => {
          commit('UPDATE_DATA', response.data.data)
          resolve(response.data)
        })
        .catch((error) => { reject(error) })
      })
    },

    editUpdate ({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.post('/task-update-add', data)
        .then((response) => {
          commit('EDIT_UPDATE', response.data.data)
          resolve(response.data)
        })
        .catch((error) => { reject(error) })
      })
    },

    removeJadwal ({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.delete(`/task-delete/${data.parent}`)
        .then((response) => {
          commit('REMOVE_JADWAL', data.id)
          resolve(response.data)
        })
        .catch((error) => { reject(error) })
      })
    }

}
